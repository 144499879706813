import React, { useState, useRef, useEffect } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Loading from "../Components/Loading";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../Components/Checkout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import baseUrl from "../Helper/config";
import axios, { toFormData } from "axios";
import SquarePayment from "../Components/SquareForm";
// const stripePromise = loadStripe(
//   "pk_test_51QAxhDGDrsu2XSIMAM4fDxVe8nlaMW2BChrHehVSoB9HsyIlvNuazyaHniSKW88u1TNgbKKLIJBRDA5iInhyuqY300gI6dlIIE"
// );

const stripePromise = loadStripe(
  "pk_live_51QAxhDGDrsu2XSIMRO3TFdHdcYLHhFdOk6bhXN3aPAJjQ4EkkmEB6iRAyHYoC27u2giGBIDRBIq5JulgzQXGKIB4001Fs0G3TN"
);

const deliveryMethods = [
  {
    id: 1,
    title: "Point to point",
    turnaround: "Pick-up & drop-off location required.",
    price: "Based on miles",
  },
  {
    id: 2,
    title: "Hourly",
    turnaround: "Only pick-up location is required",
    price: "Min. 3 hours",
  },
];

const Car = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Premium Chauffeur Car with Driver in New York";
  }, []);

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [pickUpAddress, setPickUpAddress] = useState("");
  const [dropOffAddress, setDropOffAddress] = useState("");
  const [pickUpDate, setPickUpDate] = useState("");
  const [pickUpTime, setPickupTime] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [price, setPrice] = useState("");
  const [additionalPrice, setAdditionalPrice] = useState(0);
  const [tax, setTax] = useState("");
  const [carData, setCarData] = useState({});
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  const [rideType, setRideType] = useState({});
  const [rideHours, setRideHours] = useState(3);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [additionalDetails, setAdditionalDetails] = useState({
    numberOfPassengers: "",
    numberOfBaggage: "",
    additionalDetailsText: "",
    flightNumber: "",
  });
  const handleAdditionalDetailsChange = (e) => {
    const { name, value } = e.target;
    setAdditionalDetails((prevData) => ({
      ...prevData,
      [name]: value >= 0 ? value : 0,
    }));
  };
  const handleAdditionalTextDetailsChange = (e) => {
    const { name, value } = e.target;
    setAdditionalDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [additionalSeatsDetails, setAdditionalSeatsDetails] = useState({
    boosterSeat: "0",
    childSeat: "0",
    infantSeat: "0",
  });
  const handleAdditionalSeatsDetailsChange = (e) => {
    const { name, value } = e.target;
    setAdditionalSeatsDetails((prevData) => ({
      ...prevData,
      [name]: value >= 0 ? value : 0,
    }));
  };
  useEffect(() => {
    var additionalSeatsAdd =
      Number(additionalSeatsDetails?.boosterSeat) +
      Number(additionalSeatsDetails?.childSeat) +
      Number(additionalSeatsDetails?.infantSeat);
    setAdditionalPrice(Number(10 * Number(additionalSeatsAdd) || 0));
  }, [additionalSeatsDetails]);

  useEffect(() => {
    setTax(((Number(additionalPrice) + Number(price)) * 0.04).toFixed(2));
  }, [additionalPrice]);

  useEffect(() => {
    if (!location.userData) {
      const storedData = localStorage.getItem(
        "__OrientyBlackCarServiceCarData__"
      );
      if (storedData) {
        var savedData = JSON.parse(storedData);
        setPickUpAddress(savedData?.userData?.pickUpAddress);
        setDropOffAddress(savedData?.userData?.dropOffAddress);
        setPickUpDate(savedData?.userData?.pickUpDate);
        setPickupTime(savedData?.userData?.pickUpTime);
        setVehicleType(savedData?.userData?.vehicleType);
        setCarData(savedData?.car);
        setPrice(savedData?.car?.price);
        setTax(savedData?.car?.taxCharged);
        setRideType(savedData?.userData?.rideType);
        setRideHours(savedData?.userData?.rideHours);
      }
    }
    const storedUsername = JSON.parse(
      localStorage.getItem("__OrientyBlackCarService__")
    );
    if (storedUsername) {
      setUserDetails(storedUsername);
    }
  }, []);

  return (
    <>
      {loading && <Loading />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <div className="no-bottom no-top zebra" id="content">
        <div id="top"></div>
        <section id="subheader" className="jarallax text-light">
          <img
            src="images/background/2.jpg"
            className="jarallax-img"
            alt="Professional Chauffeur Rides NYC"
          />
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Vehicle</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-car-details">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-6">
                <div id="slider-carousel" className="owl-carousel mb-3">
                  <div className="item">
                    <img
                      src={carData?.img}
                      alt="Professional Chauffeur Rides NYC"
                    />
                  </div>
                </div>
                <h3>{carData?.name}</h3>
                <p>{carData?.descriptions}</p>

                <div className="spacer-10"></div>

                <h4>Specifications</h4>
                <div className="de-spec">
                  {carData?.specifications?.length > 0
                    ? carData?.specifications?.map((spec, i) => {
                        return (
                          <div className="d-row" key={i}>
                            <span className="d-title">{spec?.name}</span>
                            <span className="d-value">{spec?.value}</span>
                          </div>
                        );
                      })
                    : null}
                </div>

                <div className="spacer-single"></div>

                <h4>Features</h4>
                <ul className="ul-style-2">
                  {carData?.features?.length > 0
                    ? carData?.features?.map((feat, i) => {
                        return <li key={i}>{feat}</li>;
                      })
                    : null}
                </ul>
              </div>

              <div className="col-lg-6">
                <div className="de-price text-center">
                  Total Price
                  <h3>
                    $
                    {Number(
                      Number(price) + Number(additionalPrice) + Number(tax)
                    ).toFixed(2)}
                  </h3>
                  <p style={{ fontSize: 12, margin: 0 }}>Subtotal: ${price}</p>
                  <p style={{ fontSize: 12, margin: 0 }}>
                    Additional Price: ${additionalPrice}
                  </p>
                  <p
                    style={{
                      fontSize: 12,
                      margin: 0,
                    }}
                  >
                    Tax Charged: ${tax}
                  </p>
                </div>
                <div className="spacer-30"></div>
                <div className="de-box mb25">
                  <div name="contactForm" id="contact_form" method="post">
                    <h4>Booking this ride: {rideType?.title}</h4>

                    <div className="spacer-20"></div>

                    <div className="row">
                      <div className="col-lg-12">
                        <h5>Pick Up Location</h5>
                        <p>{pickUpAddress}</p>
                      </div>
                      {rideType?.title === "Hourly" ? (
                        <div className="col-lg-12">
                          <h5>Booking hours</h5>
                          <p>{rideHours}</p>
                        </div>
                      ) : (
                        <div className="col-lg-12">
                          <h5>Drop Off Location</h5>
                          <p>{dropOffAddress}</p>
                        </div>
                      )}

                      <div className="col-lg-6">
                        <h5>Pick Up Date</h5>
                        <div className="date-time-field">
                          <p>{pickUpDate}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <h5>Pick Up Time</h5>
                        <div className="date-time-field">
                          <p>{pickUpTime}</p>
                        </div>
                      </div>
                    </div>

                    <h4
                      style={{
                        borderTop: "1px solid #ddd",
                        paddingTop: 10,
                        marginBottom: 0,
                      }}
                    >
                      User Details
                    </h4>
                    <div className="spacer-20"></div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="field-set">
                          <label>
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                            Name:
                          </label>
                          <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={userDetails.name}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mt-2 ">
                        <div className="field-set">
                          <label>
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                            Email:
                          </label>
                          <input
                            type="text"
                            name="email"
                            placeholder="Email Address"
                            value={userDetails.email}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mt-2 mb-4">
                        <div className="field-set">
                          <label>
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                            Phone:
                          </label>
                          <input
                            type="number"
                            placeholder="Phone"
                            name="phone"
                            value={userDetails.phone}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <h4
                      style={{
                        borderTop: "1px solid #ddd",
                        paddingTop: 10,
                        marginBottom: 0,
                      }}
                    >
                      Additional Details
                    </h4>
                    <div className="spacer-20"></div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <div className="field-set">
                          <label>
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                            No. of Passengers:
                          </label>
                          <input
                            type="number"
                            name="numberOfPassengers"
                            placeholder="No. of Passengers"
                            value={additionalDetails?.numberOfPassengers}
                            onChange={handleAdditionalDetailsChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="field-set">
                          <label>
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                            No. of Baggage:
                          </label>
                          <input
                            type="number"
                            name="numberOfBaggage"
                            placeholder="No. of Baggage"
                            value={additionalDetails?.numberOfBaggage}
                            onChange={handleAdditionalDetailsChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mt-2">
                        <div className="field-set">
                          <label>Flight Number:</label>
                          <input
                            name="flightNumber"
                            placeholder="Flight Number"
                            value={additionalDetails?.flightNumber}
                            onChange={handleAdditionalTextDetailsChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mt-2">
                        <div className="field-set">
                          <label>Additional Details:</label>
                          <textarea
                            name="additionalDetailsText"
                            placeholder="Additional Details"
                            value={additionalDetails?.additionalDetailsText}
                            onChange={handleAdditionalTextDetailsChange}
                            className="form-control"
                            rows={4}
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <h4
                      style={{
                        borderTop: "1px solid #ddd",
                        paddingTop: 10,
                        marginBottom: 0,
                      }}
                    >
                      Additional Seats Request
                    </h4>
                    <div className="spacer-20"></div>
                    <div className="row mb-4">
                      <div className="col-md-12">
                        <div className="field-set">
                          <label>
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                            No. of Booster Seats needed (4-5 Years Old) [$10]
                          </label>
                          <input
                            type="number"
                            name="boosterSeat"
                            placeholder="No. of seats needed"
                            value={additionalSeatsDetails?.boosterSeat}
                            onChange={handleAdditionalSeatsDetailsChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mt-2">
                        <div className="field-set">
                          <label>
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                            No. of Child Seats needed (1-3 Years Old) [$10]
                          </label>
                          <input
                            type="number"
                            name="childSeat"
                            placeholder="No. of seats needed"
                            value={additionalSeatsDetails?.childSeat}
                            onChange={handleAdditionalSeatsDetailsChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mt-2">
                        <div className="field-set">
                          <label>
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                            No. of Infant Seats needed (16 months or younger)
                            [$10]
                          </label>
                          <input
                            type="number"
                            name="infantSeat"
                            placeholder="No. of seats needed"
                            value={additionalSeatsDetails?.infantSeat}
                            onChange={handleAdditionalSeatsDetailsChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    {userDetails?.name &&
                    userDetails?.email &&
                    userDetails?.phone &&
                    additionalDetails?.numberOfBaggage &&
                    additionalDetails?.numberOfPassengers &&
                    additionalSeatsDetails?.boosterSeat &&
                    additionalSeatsDetails?.childSeat &&
                    additionalSeatsDetails?.infantSeat ? (
                      <div className="row">
                        <div className="col-md-12">
                          {/* <SquarePayment
                            loading={loading}
                            toast={toast}
                            setLoading={setLoading}
                            car={carData}
                            userDetails={userDetails}
                            amount={
                              Number(price) +
                              Number(additionalPrice) +
                              Number(tax)
                            }
                            taxCharged={tax}
                            navigate={navigate}
                            additionalDetails={additionalDetails}
                            additionalSeatsDetails={additionalSeatsDetails}
                            rideData={{
                              pickUpAddress,
                              dropOffAddress,
                              pickUpDate,
                              pickUpTime,
                              vehicleType,
                              rideType,
                              rideHours,
                            }}
                          /> */}
                          {/* <Elements stripe={stripePromise}>
                            <CheckoutForm
                              loading={loading}
                              toast={toast}
                              setLoading={setLoading}
                              car={carData}
                              userDetails={userDetails}
                              amount={
                                Number(price) +
                                Number(additionalPrice) +
                                Number(tax)
                              }
                              taxCharged={tax}
                              navigate={navigate}
                              additionalDetails={additionalDetails}
                              additionalSeatsDetails={additionalSeatsDetails}
                              rideData={{
                                pickUpAddress,
                                dropOffAddress,
                                pickUpDate,
                                pickUpTime,
                                vehicleType,
                                rideType,
                                rideHours,
                              }}
                            />
                          </Elements> */}
                        </div>
                        <div className="col-md-12">
                          <button
                            className="btn-main bg-warning"
                            style={{
                              width: "100%",
                            }}
                            onClick={() => {
                              setLoading(true);

                              let config = {
                                method: "post",
                                url: `${baseUrl}/v1/quoteRequest`,
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                data: JSON.stringify({
                                  carData,
                                  userDetails,
                                  rideData: {
                                    pickUpAddress,
                                    dropOffAddress,
                                    pickUpDate,
                                    pickUpTime,
                                    vehicleType,
                                    rideType,
                                    rideHours,
                                  },
                                  additionalDetails,
                                  additionalSeatsDetails,
                                }),
                              };
                              axios
                                .request(config)
                                .then((response) => {
                                  setLoading(false);
                                  if (response?.data?.success) {
                                    toast.success(
                                      "Quote request submitted. Our team will send quote soon."
                                    );
                                  } else {
                                    toast.warn(
                                      "Error!! Please try again later."
                                    );
                                  }
                                })
                                .catch((error) => {
                                  setLoading(false);
                                  //toast.warn("Error!! Please try again later.");
                                });
                            }}
                          >
                            Request Quote
                          </button>
                        </div>
                      </div>
                    ) : (
                      <p
                        style={{
                          margin: 0,
                          color: "#000",
                          fontWeight: 600,
                          color: "red",
                        }}
                      >
                        Please fill all the required fields to proceed further.
                      </p>
                    )}

                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <a href="#" id="back-to-top"></a>
      <Footer />
    </>
  );
};

export default Car;
